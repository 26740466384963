/* eslint-disable react/no-unused-prop-types */
import React from 'react'
import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Link,
  // LinkOverlay,
  // LinkBox,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  // useBreakpointValue,
  // Container,
  Heading,
  useDisclosure,
  useColorMode,
} from '@chakra-ui/react'
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  MoonIcon,
  SunIcon,
} from '@chakra-ui/icons'
// import { BsGithub } from 'react-icons/bs'
import { Link as GatsbyLink, useStaticQuery, graphql } from 'gatsby'

interface NavItem {
  label: string
  subLabel?: string
  children?: Array<NavItem>
  href?: string
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
// const NAV_ITEMS_TEST: Array<NavItem> = []

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NAV_ITEMS: Array<NavItem> = [
  {
    label: 'Blog',
    children: [
      {
        label: 'Qué es Airtm',
        subLabel: 'Cómo funciona y cómo utilizarlo',
        href: '/que-es-airtm-como-funciona/',
      },
      {
        label: '¿Es confiable Airtm?',
        subLabel: 'Echamos un vistazo a su legalidad y seguridad',
        href: '/airtm-es-legal-confiable-estafas/',
      },
      {
        label: 'Comprar dólares',
        subLabel: 'Cómo fondear en Airtm',
        href: '/como-comprar-dolares-en-airtm/',
      },
      {
        label: 'Tasa de cambio de Airtm',
        subLabel: 'Valor del dólar y otras divisas',
        href: '/tasa-de-cambio-de-airtm/',
      },
      {
        label: 'Medios de pago disponibles',
        subLabel: 'Métodos de pagos en Airtm',
        href: '/metodos-de-pago-airtm/',
      },
      {
        label: 'Qué es un cajero',
        subLabel: 'Qué significa ser cajero de Airtm',
        href: '/que-significa-ser-cajero-en-airtm/',
      },
      {
        label: 'Cómo ser cajero',
        subLabel: 'Cómo ganar dinero con Airtm',
        href: '/como-ser-cajero-en-airtm/',
      },
      {
        label: 'Cómo hacer una factura',
        subLabel: 'Cómo crear una factura digital',
        href: '/como-hacer-una-factura-para-airtm/',
      },
      {
        label: 'Cómo registrarse',
        subLabel: 'Cómo abrir una cuenta en Airtm',
        href: '/como-registrarse-en-airtm/',
      },
      {
        label: 'Cómo iniciar sesión',
        subLabel: 'Cómo saltar bloqueos para acceder a tu cuenta',
        href: '/iniciar-sesion-en-airtm/',
      },
      {
        label: 'Cómo verificar tu cuenta',
        subLabel: 'Verificación de tu identidad paso a paso',
        href: '/iniciar-sesion-en-airtm/',
      },
    ],
  },
  // {
  //   label: 'Find Work',
  //   children: [
  //     {
  //       label: 'Job Board',
  //       subLabel: 'Find your dream design job',
  //       href: '#',
  //     },
  //     {
  //       label: 'Freelance Projects',
  //       subLabel: 'An exclusive list for contract work',
  //       href: '#',
  //     },
  //   ],
  // },
  // {
  //   label: 'Learn Design',
  //   href: '#',
  // },
  // {
  //   label: 'Hire Designers',
  //   href: '#',
  // },
]

const DesktopSubNav = ({ label, href, subLabel }: NavItem) => (
  <Link
    href={href}
    role="group"
    display="block"
    p={2}
    rounded="md"
    _hover={{ bg: useColorModeValue('pink.50', 'gray.900') }}
  >
    <Stack direction="row" align="center">
      <Box>
        <Text
          transition="all .3s ease"
          _groupHover={{ color: 'pink.400' }}
          fontWeight={500}
        >
          {label}
        </Text>
        <Text fontSize="sm">{subLabel}</Text>
      </Box>
      <Flex
        transition="all .3s ease"
        transform="translateX(-10px)"
        opacity={0}
        _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
        justify="flex-end"
        align="center"
        flex={1}
      >
        <Icon color="pink.400" w={5} h={5} as={ChevronRightIcon} />
      </Flex>
    </Stack>
  </Link>
)

const DesktopNav = () => {
  const linkColor = useColorModeValue('gray.600', 'gray.200')
  const linkHoverColor = useColorModeValue('gray.800', 'white')
  const popoverContentBgColor = useColorModeValue('white', 'gray.800')

  return (
    <Stack direction="row" spacing={4}>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger="hover" placement="bottom-start">
            <PopoverTrigger>
              <Link
                p={2}
                href={navItem.href ?? '#'}
                fontSize="sm"
                fontWeight={500}
                color={linkColor}
                _hover={{
                  textDecoration: 'none',
                  color: linkHoverColor,
                }}
              >
                {navItem.label}
              </Link>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow="xl"
                bg={popoverContentBgColor}
                p={4}
                rounded="xl"
                minW="sm"
              >
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  )
}

DesktopSubNav.defaultProps = {
  subLabel: '',
  children: [],
  href: '#!',
}

const MobileNavItem = ({ label, children, href }: NavItem) => {
  const { isOpen, onToggle } = useDisclosure()

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        as={Link}
        href={href ?? '#'}
        justify="space-between"
        align="center"
        _hover={{
          textDecoration: 'none',
        }}
      >
        <Text
          fontWeight={600}
          color={useColorModeValue('gray.600', 'gray.200')}
        >
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition="all .25s ease-in-out"
            transform={isOpen ? 'rotate(180deg)' : ''}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle="solid"
          borderColor={useColorModeValue('gray.200', 'gray.700')}
          align="start"
        >
          {children &&
            children.map((child) => (
              <Link key={child.label} py={2} href={child.href}>
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  )
}

const MobileNav = () => (
  <Stack
    bg={useColorModeValue('white', 'gray.800')}
    p={4}
    display={{ md: 'none' }}
  >
    {NAV_ITEMS.map((navItem) => (
      <MobileNavItem key={navItem.label} {...navItem} />
    ))}
  </Stack>
)

MobileNavItem.defaultProps = {
  subLabel: '',
  children: [],
  href: '#!',
}

interface Data {
  site: {
    pathPrefix: string
  }
}

const WithSubnavigation = () => {
  const { isOpen, onToggle } = useDisclosure()
  const { colorMode, toggleColorMode } = useColorMode()

  const data: Data = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        pathPrefix
      }
    }
  `)

  return (
    <Box>
      <Flex
        bg={useColorModeValue('white', 'gray.800')}
        color={useColorModeValue('gray.600', 'white')}
        minH="60px"
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle="solid"
        borderColor={useColorModeValue('gray.200', 'gray.900')}
        align="center"
      >
        <Flex
          flex={{ base: 1, md: 'auto' }}
          ml={{ base: -2 }}
          display={{ base: 'flex', md: 'none' }}
        >
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant="ghost"
            aria-label="Toggle Navigation"
          />
        </Flex>
        <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
          {/* <Text
            textAlign={useBreakpointValue({ base: 'center', md: 'left' })}
            fontFamily="heading"
            color={useColorModeValue('gray.800', 'white')}
          >
            Título test
          </Text> */}
          {/* <Text
            textAlign={useBreakpointValue({ base: 'center', md: 'left' })}
            display={{ base: 'block', lg: 'inline' }}
            // w="full"
            bgClip="text"
            bgGradient="linear(to-r, green.400,purple.500)"
            fontWeight="extrabold"
            fontFamily="heading"
            fontSize="2xl"
          >
            Título test
          </Text> */}
          <Link
            to={data.site.pathPrefix === '' ? '/' : data.site.pathPrefix}
            as={GatsbyLink}
            _hover={{ textDecoration: 'none' }}
          >
            <Heading
              lineHeight={1.1}
              fontWeight={600}
              fontSize={{ base: 'lg', sm: 'xl', lg: '2xl' }}
            >
              <Text
                as="span"
                position="relative"
                background={useColorModeValue(
                  'linear-gradient(to bottom, transparent 70%, var(--chakra-colors-orange-300) 30%)',
                  'linear-gradient(to bottom, transparent 70%, var(--chakra-colors-orange-500) 30%)'
                )}
              >
                Trisquete
              </Text>
              <Text
                as="span"
                color={useColorModeValue('orange.500', 'orange.300')}
              >
                .com
              </Text>
            </Heading>
          </Link>

          <Flex display={{ base: 'none', md: 'flex' }} ml={10}>
            <DesktopNav />
          </Flex>
        </Flex>

        <Stack
          flex={{ base: 1, md: 0 }}
          justify="flex-end"
          direction="row"
          spacing={6}
        >
          {/* <Button as="a" fontSize="sm" fontWeight={400} variant="link" href="#">
            Sign In
          </Button>
          <Button
            display={{ base: 'none', md: 'inline-flex' }}
            fontSize="sm"
            fontWeight={600}
            color="white"
            bg="pink.400"
            href="#"
            _hover={{
              bg: 'pink.300',
            }}
          >
            Sign Up
          </Button> */}

          {/* <LinkBox
            as={Button}
            leftIcon={<BsGithub />}
            colorScheme="orange"
            variant="solid"
          >
            <LinkOverlay
              href="https://github.com/test"
              isExternal
              rel="noopener noreferrer"
              _hover={{
                textDecoration: 'none',
              }}
            >
              Perfil
            </LinkOverlay>
          </LinkBox> */}
          <Button onClick={toggleColorMode}>
            {colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
          </Button>
        </Stack>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box>
  )
}

export default WithSubnavigation
