import React, { ReactNode } from 'react'
import { Link as GatsbyLink, navigate } from 'gatsby'
import { Link } from '@chakra-ui/react'
import { Link as SmoothLink } from 'react-scroll'

type Props = {
  to: string
  activeClassName?: string
  partiallyActive?: boolean
  children: ReactNode
}

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
const CustomLink = ({
  children,
  to,
  activeClassName,
  partiallyActive,
  ...other
}: Props) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(to)
  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    // console.log(`Es interno${to}`)
    return (
      <Link
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        as={GatsbyLink}
        textDecoration="underline"
        _hover={{
          textDecoration: 'none',
          color: 'orange.500',
        }}
        {...other}
      >
        {children}
      </Link>
    )
  }

  const internalHash = /^#(?!\/)/.test(to)
  if (internalHash) {
    return (
      <Link
        as={SmoothLink}
        href={to}
        to={to.substring(1)}
        textDecoration="underline"
        _hover={{
          textDecoration: 'none',
          color: 'orange.500',
        }}
        spy
        smooth="easeInOutQuart"
        onClick={
          () =>
            // navigate(window.location.origin + window.location.pathname + to)
            // https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-link/#recommendations-for-programmatic-in-app-navigation
            navigate(to) // just the hashtag with anchor link
        }
        {...other}
      >
        {children}
      </Link>
    )
  }

  return (
    <Link
      href={to}
      isExternal
      rel="noopener noreferrer"
      textDecoration="underline"
      _hover={{
        textDecoration: 'none',
        color: 'orange.500',
      }}
      {...other}
    >
      {children}
    </Link>
  )
}

CustomLink.defaultProps = {
  activeClassName: '',
  partiallyActive: true,
}

export default CustomLink
