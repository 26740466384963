import React, { ReactNode } from 'react'
import {
  UnorderedList,
  OrderedList,
  ListItem,
  Text,
  Heading,
  Code,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  VStack,
  Box,
} from '@chakra-ui/react'
import CustomLink from '@/components/CustomLink'

const UL: React.FC = ({ children, ...other }) => (
  <UnorderedList listStylePosition="inside" {...other}>
    {children}
  </UnorderedList>
)

const OL: React.FC = ({ children, ...other }) => (
  <OrderedList listStylePosition="inside" {...other}>
    {children}
  </OrderedList>
)

const H1: React.FC = ({ children, ...other }) => (
  <Heading as="h1" size="2xl" {...other}>
    {children}
  </Heading>
)

const H2: React.FC = ({ children, ...other }) => (
  <Heading as="h2" size="xl" textDecoration="none !important" {...other}>
    {children}
  </Heading>
)

const H3: React.FC = ({ children, ...other }) => (
  <Heading as="h3" size="lg" {...other}>
    {children}
  </Heading>
)

const H4: React.FC = ({ children, ...other }) => (
  <Heading as="h4" size="md" {...other}>
    {children}
  </Heading>
)

const H5: React.FC = ({ children, ...other }) => (
  <Heading as="h5" size="md" {...other}>
    {children}
  </Heading>
)

const H6: React.FC = ({ children, ...other }) => (
  <Heading as="h6" size="md" {...other}>
    {children}
  </Heading>
)

const Paragraph: React.FC = ({ children, ...other }) => (
  <Text as="p" {...other}>
    {children}
  </Text>
)

const ParseLink: React.FC<{ children: ReactNode; href: string }> = ({
  children,
  href,
  ...other
}) => (
  <CustomLink to={href} {...other}>
    {children}
  </CustomLink>
)

const Wrapper: React.FC = ({ children }) => (
  <VStack pb={20} spacing={4} alignItems="flex-start" as="main" fontSize="lg">
    {children}
  </VStack>
)

const TableOverflow: React.FC = ({ children, ...other }) => (
  <Box overflowX="auto">
    <Table {...other}>{children}</Table>
  </Box>
)

const components = {
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  h5: H5,
  h6: H6,
  p: Paragraph,
  ol: OL,
  ul: UL,
  li: ListItem,
  a: ParseLink,
  table: TableOverflow,
  thead: Thead,
  tbody: Tbody,
  tfoot: Tfoot,
  tr: Tr,
  th: Th,
  td: Td,
  caption: TableCaption,
  inlineCode: Code,
  wrapper: Wrapper,
}

export default components
