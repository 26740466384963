import React from 'react'
import { MDXProvider } from '@mdx-js/react'
import Header from '@/components/HeaderAlt'
import Footer from '@/components/Footer'
import components from '@/utils/chakra-components'

const BaseLayout: React.FC = ({ children }) => (
  <MDXProvider components={components}>
    <Header />
    {children}
    <Footer />
  </MDXProvider>
)

export default BaseLayout
